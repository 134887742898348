import React from 'react';

// Components
import { CenterViewLayout } from 'components/core/layouts/center';
import { Button } from 'components/core/button';
import * as Sentry from '@sentry/react'

function Fallback({error}){
  return <CenterViewLayout>
          <label className="text-orange-500 text-sm font-medium">{`Erreur 500`}</label>

            <h1 className=" text-3xl font-bold">{`Erreur interne`}</h1>
            <p className="my-3">{`Désolé! Une erreur est survenue dans votre portail. Veuillez réessayer plus tard.`}</p>

            <Button color="default" size="lg" className={"my-3"} block href="/">{`Retour à votre page d'accueil`}</Button>
            <Button color="darkGray" size="lg" block href="/logout">{`Se déconnecter`}</Button>
            {process.env.NODE_ENV!=="production"?
            <div className="p-3 text-sm text-red-600 mt-8 bg-gray-100 rounded-lg">
              <code>{error.toString()}</code> 
            </div>: null}
         </CenterViewLayout>
}

export function ErrorBoundary({children}){
  return <Sentry.ErrorBoundary fallback={Fallback}>
        {children}
    </Sentry.ErrorBoundary>
}